@import '~@entur/tokens/dist/styles.scss';

.timelineWrapper {
  width: 100%;
  padding-right: 37px;
}

.timelineStyle {
  border-radius: $border-radiuses-medium;
  background: #DED8D8;
  overflow-y: auto;
  position: relative;
}

.hrStyle {
  background: black;
  width: 1px;
  height: 100%;
  position: absolute;
  margin-left: var(--valid-line-percentage);
}

.periodBlock {
  background: #6D92B6;
  width: 100%;
  height: 25px;
  color: #FFF;
  font-weight: 500;
  display: flex;
  border-radius: $border-radiuses-medium;
}

.textStyle {
  white-space: nowrap;
  overflow: visible;
  text-shadow: 0 0 5px black;
  text-overflow: ellipsis;
  display: block;
  margin: auto 10px;
  line-height: 18px;
  color: #FFF !important;
}

.periodLine {
  &:hover {
    opacity: .9;
  }
}
